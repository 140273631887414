const indicatifs = {
  "01": "01",
  "02": "01",
  "03": "01",
  "40": "01",
  "41": "01",
  "42": "01",
  "43": "01",
  "50": "01",
  "51": "01",
  "52": "01",
  "53": "01",
  "70": "01",
  "71": "01",
  "72": "01",
  "73": "01",
  "04": "05",
  "05": "05",
  "06": "05",
  "44": "05",
  "45": "05",
  "46": "05",
  "54": "05",
  "55": "05",
  "56": "05",
  "64": "05",
  "65": "05",
  "66": "05",
  "74": "05",
  "75": "05",
  "76": "05",
  "84": "05",
  "85": "05",
  "86": "05",
  "94": "05",
  "95": "05",
  "96": "05",
  "07": "07",
  "08": "07",
  "09": "07",
  "47": "07",
  "48": "07",
  "49": "07",
  "57": "07",
  "58": "07",
  "59": "07",
  "67": "07",
  "68": "07",
  "69": "07",
  "77": "07",
  "78": "07",
  "79": "07",
  "87": "07",
  "88": "07",
  "89": "07",
  "97": "07",
  "98": "07",
  "20 8": "21",
  "21 8": "21",
  "22 8": "21",
  "23 8": "21",
  "20 0": "25",
  "21 0": "25",
  "22 0": "25",
  "23 0": "25",
  "24 0": "25",
  "30 0": "25",
  "31 0": "25",
  "32 0": "25",
  "33 0": "25",
  "34 0": "25",
  "35 0": "25",
  "36 0": "25",
  "20 2": "27",
  "20 3": "27",
  "21 2": "27",
  "21 3": "27",
  "21 5": "27",
  "21 7": "27",
  "22 4": "27",
  "22 5": "27",
  "23 4": "27",
  "23 5": "27",
  "24 3": "27",
  "24 4": "27",
  "24 5": "27",
  "30 6": "27",
  "31 6": "27",
  "31 9": "27",
  "32 7": "27",
  "33 7": "27",
  "34 7": "27",
  "35 9": "27",
  "36 8": "27"
};

export default indicatifs;
