<template lang="pug">
.dialer.flex.flex-col
  .flex.justify-center.bg-gray-100(class="h-1/4")
    input.bg-gray-100.border-0.border-gray-200.text-center.text-3xl.placeholder-gray-300(
      class="focus:ring-0 focus:border-black" 
      type="tel" 
      placeholder="Entrez le numéro" 
      pattern="\d*"
      inputmode="numeric"
      @input="updateValue"
      v-model="phoneNumber"
    )
  .flex-1.flex.flex-col.justify-center.items-center
    div(v-if="newFormatNumber.length === 14")
      .mt-10.mb-5.text-center.text-4xl {{newFormatNumber}}
      a.mr-5.bg-blue-700.text-white.border.border-blue-700.font-bold.py-2.px-6.rounded-lg(class="w-1/4" :href="telLink") Appelez
  .flex.flex-col.items-center
    span Powered by
    img(alt="Techshelter logo" src="../assets/techshelter.png" width="200")


</template>
<script>
import { ref, computed } from "vue";
import { AsYouType } from "libphonenumber-js";
import indicatifs from "../plugin/indicatif";

export default {
  name: "Dialer",
  setup() {
    let phoneNumber = ref("");
    let newFormatNumber = computed(() => {
      {
        const pattern = Object.keys(indicatifs).filter(pattern => {
          return phoneNumber.value.startsWith(pattern) === true;
        });
        const indicatif = indicatifs[pattern];
        if (indicatif) {
          return `${indicatif} ${phoneNumber.value}`;
        }
        return "";
      }
    });
    const telLink = computed(() => {
      return `tel:${newFormatNumber.value}`;
    });
    const updateValue = () => {
      if (phoneNumber.value.length > 11) {
        phoneNumber.value = phoneNumber.value.slice(0, 11);
      }
      phoneNumber.value = new AsYouType("CI").input(phoneNumber.value);
    };
    return {
      phoneNumber,
      updateValue,
      newFormatNumber,
      telLink
    };
  }
};
</script>
<style scoped>
input:focus::placeholder {
  color: transparent;
}
.dialer {
  height: 90vh;
}
</style>
